<template>
  <div id="app">
    <hc-pano
      ref="pano"
      :src="src"
      :view="view"
      :hotpoints="hotpoints"
      :music="url"
      :rotate="rotate"
      :logo="logo"
      :click-showtext="true"
      :click-lookto="true"
      @loaded="loaded"
      @pano-view="getView"
      @pano-click="getPosition"
      @hotspot-click="click"
    />
   
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      // 全景地址
      src: './vtour/tour.xml',
      // 初始视角
      view: { hlookat: 10, vlookat: 10, fov: 90 },
      // 背景音乐
      url: '',
      // 初始热点集合
      hotpoints: [
        {
          name: '123',
          id: '467084c0ec5d43d692f977486fd8a766',
          draw: '{"points":[{"x":-35.402167117961255,"y":-6.3662301336968685,"z":0},{"x":18.491427599050837,"y":1.430085878606208,"z":0}],"fillColor":null,"borderColor":"#00CCFF","borderWith":1}',
          type: 'polyline'
        },
        {
          name: '123',
          type: 'point',
          x: -9.855712557236075,
          y: 23.417720530820066,
          showName: false,// 当参数不存在或为false，默认隐藏标签
          icon: 'https://dev.zhuneng.cn/cockpitApi/scenefile/style/23644b6c0e56463c929f46d06ca817a5/0.png',
          id: '611a87cb331d4828ac98f2070733e82d'
        },
        {
          style: [
            { borderColor: '#D4FF00', fillColor: '#FF0000', borderWith: 1 },
            { borderColor: '#FF00E1', fillColor: '#00CCFF', borderWith: 2 }
          ],
          styleIndex: 1,
          name: '321',
          id: '82902c36c3024f2c8ed8a11412b3ae01',
          draw: '{"points":[{"x":-52.60645501105404,"y":0.6411074010916622,"z":0},{"x":-49.385124246792714,"y":16.150478441446108,"z":0},{"x":-40.195395283607866,"y":-0.1152007383919005,"z":0}],"fillColor":"#FF0000","borderColor":"#D4FF00","borderWith":1}',
          type: 'polygon'
        }
      ],
      // 漫游配置
      rotate: {
        enabled: false, // 是否启用
        waittime: 0.5, // 等待时间，指用户最后操作后等待时间
        accel: 1.0, //加速度 度/秒
        speed: 5.0, //最大速度 度/秒
        horizon: 0, //移动至给定水平线，0为全景中间
        tofov: 90 //视角跳转指定fov
      },
      // logo
      logo: {
        url: '', // img地址，注意img地址是以public为相对路径
        align: 'righttop', //位置 lefttop, left, leftbottom, top, center, bottom, righttop, right, rightbottom
        x: 10, //从align到边界距离 x
        y: 10, //从align到边界距离 y
        alpha: 0.8 //透明度
      }
    }
  },
  methods: {
    loaded() {
      console.log('全景加载成功')
    },
    getView(view) {
      console.log(view)
    },
    click(item) {
      // 热点点击回调，返回热点对象
      console.log(item)
    },
    getPosition(data) {
      // 获取当前位置，用于新增热点
      console.log(data)
    },
    test() {
      this.$refs.pano.lookTo({
          name: '123',
          type: 'point',
          x: -9.855712557236075,
          y: 23.417720530820066,
          showName: false,// 当参数不存在或为false，默认隐藏标签
          icon: 'https://dev.zhuneng.cn/cockpitApi/scenefile/style/23644b6c0e56463c929f46d06ca817a5/0.png',
          id: '611a87cb331d4828ac98f2070733e82d'
        })
    },
    test2() {
      this.rotate.enabled = false
      this.$set(this.rotate, 'enabled', false)
    },
    startPaint() {
      // 画图事件，第一个fillColor null为线，否则多边形
      this.$refs.pano.startPaint(null, 'rgba(255,100,100,0.9)', 2, points => {
        // 回调为画对象的name和点集合
        console.log(points)
      })
    }
  }
}
</script>

<style>
html,
body {
  margin: 0px;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  height: 100vh;
}
</style>